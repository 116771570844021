import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "benjamin-shop"
    }}>{`Benjamin Shop`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "facility-machine"
    }}>{`Facility Machine`}</h3>
    <p>{`The facility machines will reduce the steps required in planting and raising. Buyable from in-game shop.`}</p>
    <p><img alt="mat" src={require("./public/images/fa-1.png")} /></p>
    <ul>
      <li parentName="ul">{`Sprinkler`}</li>
      <li parentName="ul">{`Egg Collector`}</li>
      <li parentName="ul">{`Automatic Milker`}</li>
      <li parentName="ul">{`Shearing Machine`}</li>
    </ul>
    <p><img alt="mat" src={require("./public/images/fa-2.png")} /></p>
    <p>{`Each facility machine can endure 10 times of use before breaking. You need to repair it to bring it back to work again.`}</p>
    <p><img alt="mat" src={require("./public/images/fa-3.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      